@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Menu1';
}
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5rem;
  padding: 0 2rem;
  background-color: #1c1c1c;
  color: var(--textColor);
  position: fixed;
  z-index: 20;
  border-bottom: 1px solid var(--CorBorda);
}

nav a {
  margin: 0 1rem;
  color: var(--CorTexto);
  text-decoration: none;
  cursor: pointer;
  font-size: 1.3rem;
}

nav a:hover {
  color: var(--CorFontApagada);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  color: var(--CorTexto);
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #1c1c1c;
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;

    z-index: '4';
    position: fixed;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}
