.Container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  width: 100%;
  height: 40rem;
  background-color: var(--CorFundo);
}

.Container h1 {
  color: var(--CorTexto);
}

@media (max-width: 900px) {
  .Container {
    flex-direction: column;
  }
}
