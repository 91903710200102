.Container {
  align-items: center;
  background: hsla(0, 0%, 67%, 0.1);
  border-radius: 2rem;
  display: flex;
  height: 7rem;
  justify-content: center;
  flex-direction: column;
  transition: all 0.5s;
  width: 23%;
  text-align: center;
}
.Container:hover {
  background: hsla(0, 0%, 37%, 0.1);
}
.Container p {
  color: var(--CorFontApagada);
}
.Container svg {
  transition: all 0.3s;
  fill: var(CorTexto);
  width: 2rem;
}
.Container svg:hover {
  fill: var(--CorFontApagada);
}
@media only screen and (max-width: 1024px) {
  .Container {
    display: flex;
    width: 29%;
    height: 0.2rem;
    background-color: transparent;

    margin-top: 2rem;
  }
  .Container svg {
    display: none;
  }
  .Container p {
    font-size: 1rem;
    text-align: center;
    border: 1px solid var(--CorFontApagada);
    border-radius: 2rem;
    background: hsla(0, 0%, 67%, 0.1);
    padding: 0.3rem;
  }
}
