.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  color: var(--CorTexto);
}
.Container span {
  display: flex;
  margin: 2rem;
  text-align: center;
  color: var(--CorTexto);
}
