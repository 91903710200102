.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30rem;
  /* border-bottom: 1px solid var(--CorBorda); */
  background-color: var(--CorFundo);
}

.Container h1 {
  color: var(--CorTexto);
}

@media (max-width: 900px) {
  .Container {
    flex-direction: column;
    height: 100%;
    padding: 1rem;
  }
}
