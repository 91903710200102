.Container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ball {
  position: relative;
  bottom: 50px;
  left: calc(100% - 20px);
  width: 60px;
  height: 60px;
  background: var(--CorBorda);
  border-radius: 50%;
  border: solid 1px #fff;
  animation: ball-move8234 3s ease-in-out 1s infinite alternate;
}

.ball::after {
  position: absolute;
  content: '';
  top: 25px;
  right: 5px;
  width: 5px;
  height: 5px;
  background: #000;
  border-radius: 50%;
}

.bar {
  position: absolute;
  width: 330px;
  height: 12.5px;
  background: var(--CorBorda);
  border-radius: 30px;
  transform: rotate(-19deg);
  animation: up-down6123 3s ease-in-out 1s infinite alternate;
}

@keyframes up-down6123 {
  from {
    transform: rotate(-60deg);
  }

  to {
    transform: rotate(1deg);
  }
}

@keyframes ball-move8234 {
  from {
    left: calc(100% - 40px);
    transform: rotate(360deg);
  }

  to {
    left: calc(0% - 20px);
    transform: rotate(0deg);
  }
}
@media only screen and (max-width: 1024px) {
  .Container {
    width: 100%;
  }
  .bar {
    margin-top: 9rem;
    width: 300px;
    height: 12.5px;
  }
}
