.Container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 30rem;
  flex-direction: column;
  padding: 2rem;
  background-color: var(--CorFundo);
  border-bottom: solid 1px var(--CorBorda);
}
.ContainerContato {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .Container {
    flex-direction: column;
  }
  .ContainerContato {
    display: flex;
    flex-direction: column;
  }
}
