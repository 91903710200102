.Container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 40rem;
  background-color: var(--CorFundo);
  /* border-bottom: 1px solid var(--CorBorda); */
}
.ContainerAnima {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}

@media only screen and (max-width: 1024px) {
  .Container {
    flex-direction: column;
    height: 55rem;
  }
  .ContainerAnima {
    width: 100%;
    align-items: center;
  }
}
