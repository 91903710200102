.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
}
.Container h1 {
  font-family: 'Menu1';
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 3rem;
  color: var(--CorTexto);
  text-align: center;
}
.Container small {
  color: var(--CorFontApagada);
  text-align: center;
}

.Container svg {
  fill: #555;
}
.Container svg:hover {
  fill: var(--CorTexto);
}

@media only screen and (max-width: 1024px) {
  .Container {
    flex-direction: column;
  }
  .Container h1 {
    font-size: 1.3rem;
  }
}
