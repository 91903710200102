.Container {
  width: 100%;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid var(--CorBorda); */
  background-color: var(--CorFundo);
}
@media only screen and (max-width: 1024px) {
  .Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    height: 50rem;
  }
}
