.Container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.2rem;
  height: 100%;
  left: 30px;
  place-content: center;
  place-items: center;
  top: 0;
  width: 40%;
  z-index: 0;
}
@media only screen and (max-width: 1024px) {
  .Container {
    width: 80%;
    align-items: center;
    justify-content: center;
  }
}
