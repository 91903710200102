* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Menu';
  src: url('../public/fontes/Cormorant_Garamond/CormorantGaramond-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Menu1';
  src: url('../public/fontes/Montserrat_Alternates/MontserratAlternates-Light.ttf')
    format('truetype');
}

:root {
  --mainColor: #555;
  --mainColorLight: #e0ffff;
  --secondaryColor: #db2b39;
  --textColor: #ffc1c1;
  --ColorHover: #eee;
  --CorFundo: #1c1c1c;
  --CorBorda: hsla(0, 0%, 67%, 0.1);
  --CorFontApagada: #aaa;
  --CorTexto: #fff;
}
