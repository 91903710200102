.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32rem;
  position: fixed;
  justify-content: right;
  align-items: flex-end;
  z-index: 30;
}
.containerSpan {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  border-radius: 15px 15px 1px 15px;
  background-color: var(--CorFundo);
  border: 1px solid var(--CorBorda);
  padding: 12px 16px 12px 16px;
  margin-right: 1.4rem;
  transition: 2s;
}
.containerSpan:hover {
  background-color: hsla(0, 0%, 67%, 0.1);
  border: 1px solid var(--CorBorda);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
}
.containerSpan span {
  color: var(--CorTexto);
  text-decoration: none;
  font-size: 1rem;
}
.containerSpan img {
  width: 1.2rem;
  margin-right: 0.5rem;
}
.containerSpan a {
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .container {
    margin-top: 37rem;
  }
}
