.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.Container img {
  display: flex;
  width: 15rem;
  transition: all 0.4s;
  width: 300px; /* Ajuste o tamanho conforme necessário */
  height: 400px; /* Ajuste o tamanho conforme necessário */
  object-fit: cover;
  border-radius: 40%; /* Raio para bordas arredondadas */
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.7);
  
}

@media only screen and (max-width: 1024px) {
  .Container img {
    width: 15rem;
    opacity: 0.8;
  }
  .Container {
    width: 70%;
  }
}
